<template>
  <v-expansion-panels accordion flat class="border-secondary">
    <v-expansion-panel v-for="(panel, index) in faqPanels" :key="index">
      <v-expansion-panel-header
        :class="[
          'font-weight-medium text-body-2 black--text',
          { 'colored-panel': index % 2 === 0 }
        ]"
      >
        {{ panel.question }}
      </v-expansion-panel-header>
      <v-divider />
      <v-expansion-panel-content :class="[{ 'colored-panel': index % 2 === 0 }]">
        {{ panel.answer }}
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'ProvidersPromoFaq',
    computed: {
      ...mapState('settings', ['adNetwork']),
      faqPanels() {
        return [
          {
            question: this.$t('providers.question_1.question'),
            answer: this.$t('providers.question_1.answer', { network: this.adNetwork.name })
          },
          {
            question: this.$t('providers.question_2.question'),
            answer: this.$t('providers.question_2.answer')
          },
          {
            question: this.$t('providers.question_3.question', { network: this.adNetwork.name }),
            answer: this.$t('providers.question_3.answer', { network: this.adNetwork.name })
          },
          {
            question: this.$t('providers.question_4.question'),
            answer: this.$t('providers.question_4.answer')
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .colored-panel {
    background-color: rgba(37, 165, 137, 0.1);
  }
  ::v-deep .v-expansion-panel-content__wrap {
    padding-top: 16px;
  }
</style>
